<template>
  <div>
    <div v-if="isTextTruncated">
      {{ truncatedText.summary }}
      <span v-if="!readMoreActivated">...</span>
      <transition name="fade">
        <span v-if="readMoreActivated">
          {{ truncatedText.more }}
        </span>
      </transition>
      <span class="clickable text-primary" @click="updateReadMore">
        {{ $t(getBtnText) }}
      </span>
    </div>
    <div v-else>
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    maxTextTruncateLength: {
      type: Number,
      default: 40
    }
  },
  data() {
    return {
      readMoreActivated: false,
      extraLength: 10
    };
  },
  computed: {
    truncatedText() {
      let textSummary = this.text.slice(0, this.maxTextTruncateLength);
      let textMore = this.text.slice(
        this.maxTextTruncateLength,
        this.text.length
      );
      const nextSpace = textMore.indexOf(" ");
      if (nextSpace < this.extraLength) {
        const startMoreTextFrom = this.maxTextTruncateLength + nextSpace;
        textSummary = this.text.slice(0, startMoreTextFrom);
        textMore = this.text.slice(startMoreTextFrom, this.text.length);
      }

      return {
        summary: textSummary,
        more: textMore
      };
    },
    isTextTruncated() {
      return (
        this.text.length > this.truncatedText.summary.length + this.extraLength
      );
    },
    getBtnText() {
      return this.readMoreActivated ? "readLess" : "readMore";
    }
  },
  methods: {
    updateReadMore() {
      this.readMoreActivated = !this.readMoreActivated;
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
